export const COLORS = [
  '#262342',
  '#7D7B8D',
  '#D4D3D9',
  '#00e28b',
  '#02a56a',
  '#91f9ce',
  '#008eff',
  '#005cff',
  '#59bafc',
  '#ff3c64',
  '#d8134b',
  '#ffb6d2',
  '#F7bf16',
  '#fff399',
  '#fe5038',
  '#ff817b',
  '#7129e2',
  '#4E08bc',
  '#d2bbff',
];

export const BORDER_COLORS = [
  '#ff3c64',
  '#d8134b',
  '#ffb6d2',
  '#F7bf16',
  '#fff399',
  '#fe5038',
  '#ff817b',
  '#7129e2',
  '#4E08bc',
  '#d2bbff',
  '#262342',
  '#7D7B8D',
  '#D4D3D9',
  '#00e28b',
  '#02a56a',
  '#91f9ce',
  '#008eff',
  '#005cff',
  '#59bafc',
];

export const TI_COLORS = [
  '#7129e2',
  '#4E08bc',
  '#d2bbff',
  '#262342',
  '#7D7B8D',
  '#D4D3D9',
  '#00e28b',
  '#02a56a',
  '#91f9ce',
  '#008eff',
  '#005cff',
  '#59bafc',
  '#ff3c64',
  '#d8134b',
  '#ffb6d2',
  '#F7bf16',
  '#fff399',
  '#fe5038',
  '#ff817b',
];

export const EXTRA_AXIS_INDICATOR_TYPES = [
  'rsi',
  'momentum',
  'roc',
  'stochastic',
];

export const SELECTION_INTRADAY = 'SELECTION_INTRADAY';
export const SELECTION_WEEK = 'SELECTION_WEEK';
export const SELECTION_HISTORIC = 'SELECTION_HISTORIC';
export const SELECTION_ONE_MONTH = 'SELECTION_ONE_MONTH';
export const SELECTION_THREE_MONTHS = 'SELECTION_THREE_MONTHS';
export const SELECTION_SIX_MONTHS = 'SELECTION_SIX_MONTHS';
export const SELECTION_ONE_YEAR = 'SELECTION_ONE_YEAR';
export const SELECTION_THREE_YEARS = 'SELECTION_THREE_YEARS';
export const SELECTION_FIVE_YEARS = 'SELECTION_FIVE_YEARS';

export const SELECTIONS = [
  SELECTION_INTRADAY,
  SELECTION_WEEK,
  SELECTION_HISTORIC,
  SELECTION_ONE_MONTH,
  SELECTION_THREE_MONTHS,
  SELECTION_SIX_MONTHS,
  SELECTION_ONE_YEAR,
  SELECTION_THREE_YEARS,
  SELECTION_FIVE_YEARS,
] as const;
export type SELECTION = typeof SELECTIONS[number];

export const PERIODS = {
  [SELECTION_INTRADAY]: '1days',
  [SELECTION_WEEK]: '5days',
  [SELECTION_HISTORIC]: '5years',
  [SELECTION_ONE_MONTH]: '1months',
  [SELECTION_THREE_MONTHS]: '3months',
  [SELECTION_SIX_MONTHS]: '6months',
  [SELECTION_ONE_YEAR]: '1years',
  [SELECTION_THREE_YEARS]: '3years',
  [SELECTION_FIVE_YEARS]: '5years',
} as const;

export const SPACES = {
  [SELECTION_INTRADAY]: 'TICK',
  [SELECTION_WEEK]: 'TICK',
  [SELECTION_HISTORIC]: 'DAY',
  [SELECTION_ONE_MONTH]: 'DAY',
  [SELECTION_THREE_MONTHS]: 'DAY',
  [SELECTION_SIX_MONTHS]: 'DAY',
  [SELECTION_ONE_YEAR]: 'DAY',
  [SELECTION_THREE_YEARS]: 'DAY',
  [SELECTION_FIVE_YEARS]: 'DAY',
} as const;

export const POINTS = {
  [SELECTION_INTRADAY]: 1000,
  [SELECTION_WEEK]: 200,
  [SELECTION_HISTORIC]: 1000,
  [SELECTION_ONE_MONTH]: 1000,
  [SELECTION_THREE_MONTHS]: 1000,
  [SELECTION_SIX_MONTHS]: 1000,
  [SELECTION_ONE_YEAR]: 1000,
  [SELECTION_THREE_YEARS]: 1000,
  [SELECTION_FIVE_YEARS]: 1000,
} as const;

export const SUFFIX = {
  '5days': '_7DAYS',
  '1months': '_1MONTH',
  '3months': '_3MONTHS',
  '6months': '_6MONTHS',
  '1years': '_1YEAR',
  '3years': '_3YEARS',
  '5years': '_5YEARS',
} as const;

export const MOMENT_PERIODS = {
  [SELECTION_INTRADAY]: { months: 0 },
  [SELECTION_WEEK]: { months: 0 },
  [SELECTION_HISTORIC]: { months: 0 },
  [SELECTION_ONE_MONTH]: { months: 1 },
  [SELECTION_THREE_MONTHS]: { months: 3 },
  [SELECTION_SIX_MONTHS]: { months: 6 },
  [SELECTION_ONE_YEAR]: { years: 1 },
  [SELECTION_THREE_YEARS]: { years: 3 },
  [SELECTION_FIVE_YEARS]: { years: 5 },
} as const;

export const BAR_SERIES = {
  turnover: 'TURNOVER',
  volume: 'VOLUME',
} as const;

export const RECOMMENDATION_TYPES = {
  1: 'Sell',
  2: 'Reduce',
  4: 'Accumulate',
  5: 'Buy',
} as const;

export const TYPE_BUY = 1;
export const TYPE_SELL = 0;
