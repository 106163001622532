import styled from '@xstyled/emotion';
import { Icon } from './Icon';
import { faSpinnerThird } from './icons/light';

export const Spinner = styled(Icon)`
  animation-name: spin;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

Spinner.defaultProps = {
  icon: faSpinnerThird,
};
