import Immutable from 'immutable';
import moment from 'moment';
import template from 'url-template';

import { SELECTION_INTRADAY, SELECTION_WEEK } from './constants';

export const doubleEncode = (data: string) =>
  encodeURIComponent(encodeURIComponent(data));

export function buildURL(templateString: string, params: object) {
  const pattern = template.parse(templateString);

  return pattern.expand(params);
}

export function getSpaceFromSelection(selection = SELECTION_WEEK) {
  switch (selection) {
    case SELECTION_INTRADAY:
    case SELECTION_WEEK:
      return 'tick';
    default:
      return 'day';
  }
}

export function getSeriesFromSelection(selection = SELECTION_WEEK) {
  switch (selection) {
    case SELECTION_INTRADAY:
    case SELECTION_WEEK:
      return 's1';
    default:
      return '(CLOSE_CA)';
  }
}

function hasSeries(obj: object) {
  return !!obj && 'rows' in obj;
}

export type Break = { from: number; to: number };

type Series = {
  series: {
    key: string;
    type: string;
    data: [number, number][];
    columns: any;
  }[];
  buckets: Bucket[];
};

type RawBucket = {
  selection: {
    start: number;
    stop: number;
  };
  sector: {
    timezone: {
      shortName: string;
      UTCoffsetMinutes: number;
    };
    close: number;
    open: number;
  };
  _links: {
    self: {
      href: string;
    };
  };
};

type Bucket = {
  close?: string;
  open?: string;
  stop?: string;
  start?: string;
  timezone: string;
  UTCOffsetMinutes: number;
  link: string;
  isFetching: boolean;
};

export async function getSeries({
  data = {},
  type = 'line',
  searchParams,
}: {
  data: any;
  type?: string;
  searchParams?: URLSearchParams | undefined;
}): Promise<Series> {
  if (hasSeries(data)) {
    const { series } = data.rows[0].values;
    const parsedSeries = Immutable.Map(series)
      .map((s: any, key) => ({
        key: key as string,
        type,
        data: s.data,
        columns: s.columns,
      }))
      .toArray();
    return { series: parsedSeries, buckets: [] };
  }

  const { graphdata } = data?._embedded;

  const buckets: Bucket[] = graphdata.map((bucket: RawBucket) => {
    const { selection, sector } = bucket;
    const { stop, start } = selection;
    const { timezone, close, open } = sector;
    return {
      close,
      open,
      stop,
      start,
      timezone: timezone.shortName,
      UTCOffsetMinutes: timezone.UTCoffsetMinutes,
      link: searchParams
        ? bucket._links.self.href + '&' + searchParams?.toString()
        : bucket._links.self.href,
      isFetching: true,
    };
  });

  return await getWeekGraphData(buckets);
}

export async function getSingleDay(bucket: Bucket) {
  const result = await fetch(bucket.link, {
    credentials: 'include',
  });

  if (!result.ok) return null;

  return result.json();
}

export async function getWeekGraphData(buckets: Bucket[]): Promise<Series> {
  const result = await Promise.all(buckets.map(getSingleDay));
  const graphData: any = [];

  await Promise.all(
    result?.map(async (day) => {
      const { series } = await getSeries({ data: day });
      graphData.push(series);
    }),
  );

  const allData: any = [];
  graphData.map((item: any) => allData.push(...item[0].data));

  const finalArray = [];
  finalArray.push({
    columns: ['TIME', 'LAST'],
    data: allData,
    type: 'line',
    key: 's1',
  });
  return { series: finalArray, buckets };
}

export function getBreaks(incomingBuckets: Bucket[]): Break[] {
  if (!Array.isArray(incomingBuckets) || incomingBuckets.length < 2) return [];

  const result = incomingBuckets.map((value, key, buckets) => {
    const next = buckets[key + 1];
    if (!next) return null;

    return {
      from: moment(value.close).valueOf(),
      to: moment(next.open).valueOf(),
    };
  });

  return result.filter((item) => item) as Break[];
}

export const getRequestInit = (init: RequestInit) => {
  const env = process.env as NodeJS.ProcessEnv & {
    NODE_ENV: string;
    STORYBOOK: string;
  };
  const isDev = env?.NODE_ENV === 'development';
  const isStorybook = env?.STORYBOOK === 'true';
  if (isDev && isStorybook) {
    const overrideInit: RequestInit = {
      ...init,
      credentials: 'omit',
    };
    return overrideInit;
  }
  return init;
};
