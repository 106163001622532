import * as React from 'react';
import { formatNumber } from '@oms/utils';
import { SearchProps } from '@oms/ui-search';

export const getTrend = (value: number) => {
  return Math.sign(value) === 1
    ? 'pos'
    : Math.sign(value) === -1
    ? 'neg'
    : undefined;
};

export const RenderItem: SearchProps['renderItem'] = React.forwardRef(
  function RenderItem(
    {
      item,
      inputValue,
      isHighlighted,
      isSelected,
      itemProps,
      itemToString,
      ItemContainer,
      // Spelling mistake, fix on ui-alpha.19 release
      HighLightedText,
      HighlightedText = HighLightedText,
      ...containerProps
    }: any,
    ref: React.Ref<any>,
  ) {
    return (
      <ItemContainer
        ref={ref}
        {...containerProps}
        data-highlighted={isHighlighted}
        data-selected={isSelected}
      >
        <div>
          <HighlightedText
            display="block"
            search={inputValue || ''}
            autoEscape={true}
          >
            {item?.ITEM || ''}
          </HighlightedText>
          <HighlightedText
            display="block"
            search={inputValue || ''}
            autoEscape={true}
            variant="body1"
          >
            {item?.LONG_NAME || ''}
          </HighlightedText>
        </div>
        {item?.CHANGE_PCT ? (
          <div
            style={{
              fontSize: '0.875rem',
              marginLeft: 'auto',
              color: getTrend(item?.CHANGE_PCT) === 'pos' ? 'green' : 'red',
            }}
          >
            {formatNumber(item?.CHANGE_PCT)} %
          </div>
        ) : null}
      </ItemContainer>
    );
  },
);
