import styled from '@xstyled/emotion';
import { th, variant } from '@xstyled/system';
import { Icon, light } from '@oms/ui-icon';
import Highlighter from 'react-highlight-words';

export const ClearButton = styled.button`
  border: none;
  margin-right: 2;
  &::after {
    cursor: pointer;
    color: text;
    padding: 2;
    font-size: lg;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: '✕';
  }
`;
ClearButton.displayName = 'ClearButton';

export const Spinner = styled(Icon)`
  animation-name: spin;
  animation-duration: 500ms;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  margin-right: 1rem;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`;

Spinner.defaultProps = {
  icon: light.faSpinnerThird,
};

export const HighlightedText: typeof Highlighter = styled(Highlighter)`
  font-size: md;
  mark {
    background-color: inherit;
    color: selected;
  }
  ${th('text.default')}
  ${variant({
    key: 'text.variants',
    default: 'body2',
  })}
  ${th('search.highlightedText')}
`;
